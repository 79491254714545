.count-up-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);
  transition: background 0.3s;
  cursor: pointer;
}

.count-up-block.active {
  background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {
  .count-up-block {
    padding: 10px;
  }
}
