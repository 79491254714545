.video-section-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;
}

.video-section-buttons [data-component='Button'] {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .video-section-buttons {
    flex-direction: column;
  }
}
