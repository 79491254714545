.block-sticky-cards .content-wrap,
.block-sticky-cards .section {
  overflow: visible;
}

.block-sticky-cards .sticky-section + .sticky-section {
  margin-top: 5vh;
}

@media (min-width: 992px) {
  .block-sticky-cards .sticky-section {
    --sticky-top-gap: 30px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    top: calc(120px + var(--sticky-top-gap));
  }

  .block-sticky-cards .sticky-section + .sticky-section {
    margin-top: 20vh;
  }
}
