.quadrant-loop-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden; /* Prevents scrollbars from appearing */
  background: var(--cnvs-section-bg);
}

.quadrant {
  position: absolute;
  width: calc(50% - 5px);
  height: calc(50% - 5px);
  background-color: #e0e0e0;
  filter: grayscale(100%);
  opacity: 0.2;
  transition: opacity 0.5s, filter 0.5s, width 0.5s, height 0.5s;
  border-radius: 50px;
  overflow: hidden;
}

.quadrant.active {
  opacity: 1;
  z-index: 1;
  width: 70%;
  height: 70%;
  filter: grayscale(0%);
  opacity: 1;
}

.quadrant .quadrant-content {
  opacity: 0;
  transition: opacity 0.5s;
}

.quadrant.active .quadrant-content {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .quadrant.active {
    width: 90%;
    height: 90%;
    z-index: 100;
  }
}

.top-left {
  top: 0;
  left: 0;
}

.top-right {
  top: 0;
  right: 0;
}

.bottom-left {
  bottom: 0;
  left: 0;
}

.bottom-right {
  bottom: 0;
  right: 0;
}

.center-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.arm {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 50px; /* Adjusted to 50% of the container's height */
  background-color: white;
  border-radius: 3px;
  transform-origin: top center;
  transform: translate(-50%, -100%) rotate(180deg);
  transition: transform 0.5s;
  z-index: 10;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
}

.glowing-circle {
  position: absolute; /* Positioned relative to the section's center */
  top: 50%;
  left: 50%;
  z-index: 5;
  transform: translate(-50%, -50%); /* Center it precisely */
  width: 100px; /* Adjust as needed */
  height: 100px; /* Keep it circular */
  background: rgba(255, 255, 255, 0.3); /* Neutral grey color */
  border-radius: 50%; /* Make it a perfect circle */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1),
    /* Subtle drop shadow */ 0 0 25px rgba(255, 255, 255, 0.6); /* Glowing effect */
  animation: pulse 2s infinite; /* Optional glowing animation */
}

/* Optional glowing effect animation */
@keyframes pulse {
  0% {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1), 0 0 20px rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1), 0 0 40px rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1), 0 0 20px rgba(255, 255, 255, 0.2);
  }
}
