.buttons-container {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: relative;
  scroll-margin-top: 130px;
}

.buttons-container > * {
  margin-right: 10px;
}

.buttons-container-heading-block:after {
  border-width: 1px !important;
  width: 300px;
}

@media screen and (max-width: 768px) {
  .buttons-container > * {
    margin-right: 3px;
  }
  .buttons-container-heading-block:after {
    border-width: 1px !important;
    width: 200px;
  }
}

.buttons-container:after {
  content: '\f131';
  display: block;
  position: absolute;
  bottom: -37px;
  right: 54px;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.buttons-container:before {
  content: '\f111';
  display: block;
  position: absolute;
  bottom: -33px;
  left: 54px;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .buttons-container:after {
    display: none;
  }
  .buttons-container:before {
    display: none;
  }
}
