.phone-form {
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.phone-input {
  background-color: #dadada;
  border: 2px solid white;
  border-right: none;
  border-radius: 0;
  min-height: 4rem;
  margin-bottom: 0;
  flex-grow: 1;
  padding: 0 1rem;
  font-size: 1.25rem;
  border-top-left-radius: 6;
  border-bottom-left-radius: 6;
}

.phone-form-button {
  border: 1px solid var(--green);
  color: white;
  text-transform: uppercase;
  justify-content: flex-start;
  align-items: center;
  min-height: 3.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-size: 1.125rem;
  text-decoration: none;
  transition: all 0.3s;
  display: inline-flex;
  background-color: #8c107f;
  background-position: 20px;
  background-repeat: no-repeat;
  background-size: auto;
  border-style: none;
  min-height: 4rem;
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .phone-form {
    flex-direction: column;
  }
  .phone-input {
    width: 100%;
    border-radius: 0;
    border-top-left-radius: 6;
    border-bottom-left-radius: 6;
    margin-bottom: 6px;
    border-right: 2px solid white;
  }
  .phone-form-button {
    width: 100%;
    border-radius: 0;
    border-top-right-radius: 6;
    border-bottom-right-radius: 6;
    justify-content: center;
  }
}
