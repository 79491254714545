.quadrant-container {
  width: '100%';
  height: '100%';
  position: 'relative';
  padding: 60;
}

.quadrant-content {
  position: absolute;
  padding: 20px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.quadrant.bottom-left .quadrant-content,
.quadrant.bottom-right .quadrant-content {
  bottom: 0;
}
