.world-section {
  background: transparent;
  height: 100%;
}

.map-img {
  min-height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
